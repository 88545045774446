import { loadManifest } from '@angular-architects/module-federation';
import '@angular/localize/init';  //Todo - check if RTSE can share it without importing it in MNRE
import { environment } from './environments/environment';
// import('./bootstrap')
// 	.catch(err => console.error(err));

const manifestPath = environment.env === 'prod'
  ? "assets/mf.manifest.json"
  : "assets/mf.manifest.dev.json";

console.log("Manifest path is -- " + manifestPath);

loadManifest(manifestPath)
	.catch(err => console.error(err))
	.then(_ => import('./bootstrap'))
	.catch(err => console.error(err));

if (environment.env === "prod") {
	// overwrite the default console methods with empty
	console.log = () => { };
	console.debug = () => { };
	console.warn = () => {};
	console.error = () => {};
}